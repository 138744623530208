// reactstrap components
import { Container, Col, Row, Button } from "reactstrap";

import React from "react";

function LandingPageHeader({ home }) {
  const size =
    document.body.clientWidth >= 928 || document.documentElement.clientWidth >= 928 ? true : false;

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (document.documentElement.scrollTop > 50 || document.body.scrollTop > 50) {
      } else if (document.documentElement.scrollTop < 300 || document.body.scrollTop < 300) {
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });

  return (
    <>
      <div
        className="page-header"
        style={{
          backgroundColor: "#000",
          backgroundImage: `url(${require("assets/images/image3.jpg")})`,
          backgroundSize: size ? "100%" : "cover",
          backgroundRepeat: "no-repeat",

          // marginTop: size ? "7.8rem" : "0rem",
          paddingBottom: size ? "8.2rem" : "10rem",
        }}
        data-parallax={true}
      >
        {/* <div className="overlay-primary"></div> */}

        <Container>
          <Row className="align-items-center justify-content-start">
            <Col md={6} xs={12} className="text-left">
              <img
                src={require("assets/images/Logo.png")}
                width="100%"
                alt="main logo"
                className="pt-5"
              />
              <h3 className="text-white text-400 text-right mt-2">
                6<sup>th</sup> SEPTEMBER, 2023 | DUBAI, UAE
              </h3>

              <Container fluid className="pt-3">
                <Row>
                  <Col lg={12} className="pb-3 px-0 text-right">
                    <Button
                      href="/register"
                      className="navbar-item-custom text-400 text-dark rounded-0 "
                      style={{
                        backgroundImage: "linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%)",
                        border: "2px solid #000",
                      }}
                      size="lg"
                    >
                      <span
                        style={{
                          color: "#000",
                          textTransform: "none",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                        className="text-600"
                      >
                        REGISTER NOW <i class="fa fa-arrow-right" />
                      </span>
                    </Button>
                    <Button
                      href="/sponsor"
                      className="navbar-item-custom text-400 text-dark rounded-0"
                      style={{
                        backgroundImage: "linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%)",
                        border: "2px solid #000",
                      }}
                      size="lg"
                    >
                      <span
                        style={{
                          color: "#000",
                          textTransform: "none",
                          fontWeight: 700,
                          fontSize: "16px",
                        }}
                        className="text-600"
                      >
                        BECOME A SPONSOR
                        <i class="fa fa-arrow-right" />
                      </span>
                    </Button>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
