import React from "react";
import { Container, Row, Col, Button, Card } from "reactstrap";

import "react-circular-progressbar/dist/styles.css";
import SpeakerCard from "./SpeakerCard";

function TextGrid() {
  let pageHeader = React.createRef();
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;

  return (
    <>
      <section id="about">
        <div
          style={{
            background: "#000",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className="section py-0"
          data-parallax={true}
          ref={pageHeader}
        >
          <Container>
            <Row className="">
              <Col
                md={12}
                xs={12}
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div
                  className="text-dark"
                  style={{ padding: size ? "0rem 1rem 3rem 1rem" : "3rem 1rem 4rem 1rem" }}
                >
                  <h1
                    className="header-text text-left text-white"
                    style={{ fontSize: size && "5rem" }}
                  >
                    Where Visionaries Converge
                  </h1>
                  <h3 className="text-400 text-white text-left">
                    Welcome to the i3 Summit - Innovate, Inspire, Influence - an unparalleled
                    gathering of distinguished individuals from around the world. This business
                    event promises an extraordinary experience that will foster groundbreaking
                    collaborations, ignite inspiration, and drive economic growth and development in
                    the country.
                    <br /> <br />
                    At i3, we bring together government ministers, top CEOs, and trailblazers from
                    startups, life sciences, artificial intelligence, and renewables to tackle a
                    compelling agenda. This exclusive invitation-only event is meticulously designed
                    for CEOs, presidents, managing directors, and founders who are committed to
                    leveraging business as a catalyst for elevating humanity.
                    <br /> <br />
                    Embrace the opportunity to join an esteemed league of exceptional thought
                    leaders at i3 - an event that will redefine the landscape of innovation, inspire
                    revolutionary change, and shape the future of business. By participating, you
                    become part of an unparalleled journey towards unprecedented success. <br />{" "}
                    <br />
                    Elevate your influence, unleash your creativity, and become a driving force in
                    shaping the future of business. Together, let us Innovate, Inspire, and
                    Influence the world. Join us at the i3 Summit!
                  </h3>
                  <br />
                </div>
              </Col>
            </Row>
          </Container>
          <Container className=" mb-3">
            <Row className="justify-content-center ">
              <Col lg={12}>
                <h1 className="header-text m-0 my-3 pt-2 text-left">KEYNOTE SPEAKER</h1>
              </Col>
              <Col md={7} xs={12} className="align-self-top py-0 border-right">
                <h3 className={`text-400 text-white pl-2`}>
                  <b>Dr. Shashi Tharoor</b> is a multifaceted individual, excelling as an author,
                  politician, and former international civil servant. With a wealth of diverse
                  experiences, he effortlessly navigates between different realms. Currently, he
                  serves as a Lok Sabha MP for the Thiruvananthapuram constituency, embarking on his
                  third term, and holds the esteemed position of Chairman of the Parliamentary
                  Standing Committee on Information Technology.
                </h3>
              </Col>
              <Col md={5} xs={12} className="align-self-top ">
                <img src={require("assets/images/shashi.png")} width="100%" alt="shashi" />
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <div
        style={{
          backgroundColor: "#000",
        }}
        className="section pt-0"
        data-parallax={true}
        ref={pageHeader}
      >
        <Container className="pb-5">
          <Row className="justify-content-center">
            <Col lg={12} className="text-dark text-left ">
              <h1 className="header-text m-0 my-5">KEY FEATURES</h1>
            </Col>
            <Col lg={12}>
              <Row className="mt-3 justify-content-center">
                {content1.map((c, i) => (
                  <Col lg={6} xs={12} className="pb-2 text-center" key={i}>
                    <Card
                      style={{
                        background: "transparent",
                        boxShadow: "none",
                      }}
                    >
                      <div className="container-fluid">
                        <Row>
                          <Col xs={4}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                width: size ? "150px" : "100px",
                                height: size ? "150px" : "100px",
                                background:
                                  "linear-gradient(90deg, rgba(77,77,77,1) 0%, rgba(219,219,219,1) 88%)",
                                borderRadius: "50%",
                              }}
                            >
                              <div
                                style={{
                                  width: size ? "145px" : "95px",
                                  height: size ? "145px" : "95px",
                                  background: "black",
                                  borderRadius: "50%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <h1 className="text-white mt-0">0{i + 1}</h1>
                              </div>
                            </div>
                          </Col>
                          <Col xs={8}>
                            <h3 className="text-600 text-left mt-2 mb-2 text-white">{c.title}</h3>
                            <h4 className="text-400 text-left mt-2 mb-4 text-white">
                              {c.description}
                            </h4>
                          </Col>
                        </Row>
                      </div>
                    </Card>
                  </Col>
                ))}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <section id="speakers">
        <SpeakerCard />
      </section>
      <section id="attend">
        <div
          style={{
            background: "#000",
            backgroundImage: size && `url(${require("assets/images/image4.png")})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
          }}
          className={size ? "page-header" : "section"}
          data-parallax={true}
          ref={pageHeader}
        >
          <Container>
            <Row className="justify-content-start">
              <Col
                md={7}
                xs={12}
                className="align-self-end"
                style={{
                  // backgroundImage: size && `url(${require("assets/images/text1.png")})`,
                  backgroundSize: "cover",
                  backgroundRepeat: "none",
                }}
              >
                <div className="">
                  <h1 className="header-text text-left text-white" text-center>
                    WHO ATTENDS ?
                  </h1>
                  <h3 className="text-600 text-white">
                    <ul>
                      <li>CEOS AND PRESIDENTS </li>
                      <br />
                      <li>MANAGING DIRECTORS </li>
                      <br />
                      <li>FOUNDERS AND ENTREPRENEURS </li>
                      <br />
                      <li>THOUGHT LEADERS GOVERNMENT OFFICIALS </li>
                      <br />
                      <li>INDUSTRY EXPERTS</li>
                      <br />
                      <li>INVESTORS AND VENTURE CAPITALISTS</li>
                      <br />
                      <li>NEXT GENERATION LEADERS</li>
                      <br />
                    </ul>
                  </h3>
                  <br />
                  <Button
                    href="/register"
                    className="navbar-item-custom text-400 text-dark rounded-0 "
                    style={{
                      backgroundImage: "linear-gradient(315deg, #bdd4e7 0%, #8693ab 74%)",
                      border: "2px solid #000",
                    }}
                    size="lg"
                  >
                    <span
                      style={{
                        color: "#000",
                        textTransform: "none",
                        fontWeight: 700,
                        fontSize: "16px",
                      }}
                      className="text-600"
                    >
                      REGISTER NOW <i class="fa fa-arrow-right" />
                    </span>
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
}

export default TextGrid;

const content1 = [
  {
    title: "Exclusive Invitation",
    description:
      "i3 Summit is an exclusive event, attracting top executives from globally recognized industry leaders.",
    image: "WP 8.png",
  },
  {
    title: "ELITE PARTICIPANTS",
    description:
      "Our attendees include Chairmen, Presidents, CEOs, and distinguished scholars who are leaders in their respective fields.",
    image: "WP 7.png",
  },
  {
    title: "BROAD SPECTRUM OF TOPICS",
    description:
      "The summit covers a wide range of subjects, from global strategies to effective leadership succession planning.",
    image: "WP 4.png",
  },
  {
    title: "ENGAGING INTERACTIVE FORMAT",
    image: "WP 5.png",
    description:
      "Our unique format fosters peer-driven interactions, specifically tailored for accomplished high-profile individuals.",
  },
];
