import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const DataContext = createContext({});

export default function DataContainer({ children }) {
  const [data, setData] = useState({
    agenda: [],
    speakers: [],
    sponsors: [],
  });

  useEffect(() => {
    fetch("https://api.khaleejtimesevents.com/i3/data.json?v=20240831", {
      method: "get"
    })
      .then((response) => response.json())
      .then((data) => {
        setData({
          speakers: data.speakers,
          agenda: data.agenda,
          sponsors: data.sponsors,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  return <DataContext.Provider value={{ ...data }}>{children}</DataContext.Provider>;
}
