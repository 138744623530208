import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import "assets/css/paper-kit.min.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import Home from "./views/home.js";
import RegisterPage from "./views/Register.js";
import ThankYouRegister from "./views/ThankYouRegister";
import PrivacyPolicy from "./views/PrivacyPolicy";
import Termsconditons from "./views/Termsconditions";
import SponsorSingle from "./views/SponsorSingle";

import Speakers from "./views/Speakers";

import DataProvider from "./DataContainer";
import ContactUs from "./views/ContactUs.js";

ReactDOM.render(
  <DataProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Home {...props} />} />
        <Route
          path="/register"
          exact
          render={(props) => (
            <RegisterPage {...props} type="REGISTER" title="Register Now" subject="" />
          )}
        />

        <Route path="/contact" exact render={(props) => <ContactUs {...props} />} />
        <Route
          path="/request-brochure"
          exact
          render={(props) => (
            <RegisterPage {...props} type="AGENDA" title="Download Brochure" subject="" />
          )}
        />
        <Route path="/sponsors/:sponsor" exact render={(props) => <SponsorSingle {...props} />} />
        <Route
          path="/sponsor"
          exact
          render={(props) => (
            <RegisterPage {...props} type="SPONSOR" title="Sponsor i3 Summit" subject="" />
          )}
        />
        <Route path="/privacy-policy" exact render={(props) => <PrivacyPolicy {...props} />} />
        <Route path="/terms" exact render={(props) => <Termsconditons {...props} />} />
        <Route path="/speakers" exact render={(props) => <Speakers {...props} />} />

        <Route path="/thank-you/:id" exact render={(props) => <ThankYouRegister {...props} />} />
      </Switch>
    </BrowserRouter>
  </DataProvider>,
  document.getElementById("root")
);
